import React,{useContext} from 'react'
import { Redirect } from 'react-router-dom'
import {Grid, Container} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {UserContext} from '../../context/UserContext'

const useStyles = makeStyles((theme)=>({
	titleText: {
		textAlign: 'center',
		fontSize: '3.5rem',
		color: '#37364c'
	},
	secondaryTitle: {
		fontSize: '2.5rem',
		color: '#37364c'
	},
	descriptionText: {
		fontSize: '1.3rem',
		color: '#37364c',
		lineHeight: '130%'
	},
	media_image: {
		width: '100%',
		borderRadius: '10px'
	},
	content_container: {
		paddingTop: '50px',
		paddingBottom: '50px',
		backgroundColor: '#f0f0f0'
	}
}))
function Aboutus() {
	const classes = useStyles()
	const {isLoggedIn} = useContext(UserContext)
	if(isLoggedIn){
		return (<Redirect to="/dashboard"/>)
	}
	return (
		<div>
			<h1 className={classes.titleText}>About Magpie Analytics</h1>
			<div className={classes.content_container}>
				<Container maxWidth="lg">
					<Grid container spacing={3} alignItems="center">
						<Grid item md={6}>
							<img src="https://cdn.pixabay.com/photo/2020/07/11/22/57/business-meeting-5395567_960_720.jpg" alt="about us" className={classes.media_image}/>
						</Grid>
						<Grid item md={6}>
							<h2 className={classes.secondaryTitle}>What we do?</h2>
							<p className={classes.descriptionText}>Magpie aims to assist customers to make the right business decisions with the help of useful analytics. Starting from small start-ups to large corporations, all kinds of decisions are taken based on internal data. Magpie integrates its customer’s unique needs with business intelligence and data analytics technology to build a framework and supply major business insights in order to better understand and implement their business goals. </p>
						</Grid>
					</Grid>
				</Container>
			</div>
		</div>
	)
}

export default Aboutus
