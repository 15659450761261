import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'

const firebaseConfig = {
	apiKey: "AIzaSyBmTfeK_rFwy48zZFEPMmyNTjp_Hx7U7uQ",
	authDomain: "magpiereport.firebaseapp.com",
	databaseURL: "https://magpiereport.firebaseio.com",
	projectId: "magpiereport",
	storageBucket: "magpiereport.appspot.com",
	messagingSenderId: "663793221649",
	appId: "1:663793221649:web:3af414cfb5f7f57556b1b0",
	measurementId: "G-342SX99SQ8"
};

firebase.initializeApp(firebaseConfig)

export default firebase