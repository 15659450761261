import React, {useContext} from 'react'
import {isAndroid} from 'react-device-detect'
import {Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {useHistory, Redirect} from 'react-router-dom'
import {UserContext} from '../../context/UserContext'
import google_play  from '../../assets/icons/google_play.png'

const imageurl = 'https://i.ibb.co/wBdxxzM/background.png'

const useStyles = makeStyles(theme=>({
	home_page: {
		display: 'flex',
		minHeight: 'calc(100vh - 84px)',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundImage: `url(${imageurl})`,
		backgroundSize: 'cover',
		[theme.breakpoints.down('xs')]: {
			minHeight: 'calc(100vh - 76px)',
		}
	},
	home_page_content: {
		textAlign: 'center',
		color: '#ffffff',
		'& .heading': {
			fontSize: '3.5rem',
			margin: '0',
			[theme.breakpoints.down('md')]: {
				fontSize: '2.5rem',
			}
		},
		'& .subheading': {
			fontSize: '1.3125rem',
			fontWeight: '400',
			margin: '1.75rem 0px',
			[theme.breakpoints.down('md')]: {
				fontSize: '1.25rem',
			}
		},
		'& .btn_wraper': {
			[theme.breakpoints.down('xs')]: {
				display: 'flex',
				flexDirection: 'column',
				padding: '0 20px',
				'& button:first-child': {
					marginBottom: '10px'
				}
			},
			[theme.breakpoints.up('sm')]: {
				'& button:first-child': {
					marginRight: '10px'
				}
			}
		}
	}
}))

function HomePage() {
	const classes = useStyles()
	const history = useHistory()
	const {isLoggedIn} = useContext(UserContext)
	if(isLoggedIn){
		return (<Redirect to="/dashboard"/>)
	}
	return (
		<div className={classes.home_page}>
			<div className={classes.home_page_content}>
				<h1 className="heading">Find clarity when you need it most</h1>
				<h5 className="subheading">Empower team members to discover insights hidden in your data with Microsoft Power BI.</h5>
				<div className="btn_wraper">
					{!isAndroid? (
						<>
						<Button variant="contained" className="primary-color" onClick={()=>{history.push('/mobileverifier')}}>Sign Up For Free Trial</Button>
						<Button variant="contained" className="secondary-color" onClick={()=>{history.push('/login')}}>Login</Button>
						</>	
					) : (
					<>
						<Button variant="contained" className="primary-color" onClick={()=>{window.open('https://magpieanalysis.page.link/android_app', '_blank')}}><img src={google_play} className="mr-1" width="20" alt="google play button"/> Continue With Our App</Button>
						<Button variant="contained" className="secondary-color" onClick={()=>{history.push('/login')}}>Continue With Web</Button>
					</>)}
				</div>
			</div>
		</div>
	)
}

export default HomePage