import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import '../../styles/navbar.scss'
import { Container, IconButton, 
	Toolbar, AppBar, 
	Drawer, List, ListItem} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Menu} from '@material-ui/icons'
import logo from '../../assets/icons/magpie.png'

const useStyles = makeStyles(theme=>({
	itemList: {
		minWidth: "150px",
	},
	listLink: {
		color: "#37364c",
		textDecoration: "none",
		display: 'block',
		width: '100%'
	}
}))
function Navbar() {
	const [drawerOpen, serDrawerOpen] = useState(false)
	const classes = useStyles()
	const closeDrawer = (open)=> ()=>{
		serDrawerOpen(open)
	}
	const navigateTo = (url) => () =>{
		window.open(url, "_blank")
	}
	return (
		<AppBar position="static" className="navbar">
			<Drawer anchor="left" open={drawerOpen} onClose={closeDrawer(false)}>
				<List className={classes.itemList} onClick={closeDrawer(false)}>
					<ListItem button key='home'>
						<Link to="/" className={classes.listLink}>Home</Link>
					</ListItem>
					{/* <ListItem button key='pricing'>
						<Link to="/pricing" className={classes.listLink}>Pricing</Link>
					</ListItem> */}
					<ListItem button key='services'>
						<Link to="#" 
						onClick={navigateTo('https://www.magpieanalytics.com/business-intelligence-consulting-services/')} className={classes.listLink}>Business Intelligence</Link>
					</ListItem>
					<ListItem button key='services'>
						<Link to="#" 
						onClick={navigateTo('https://data.magpieanalytics.com/')} className={classes.listLink}>Industry Analytics</Link>
					</ListItem>
					<ListItem button key='blog'>
						<Link to="#" 
						onClick={navigateTo('https://www.magpieanalytics.com/blog/')} className={classes.listLink}>Blog</Link>
					</ListItem>
					<ListItem button key='aboutus'>
						<Link to="#" 
						onClick={navigateTo('https://www.magpieanalytics.com/magpie-about/')} className={classes.listLink}>About Us</Link>
						
					</ListItem>
					<ListItem button key='contactus'>
						<Link to="#" 
						onClick={navigateTo('https://www.magpieanalytics.com/magpie-contact/')} className={classes.listLink}>Contact</Link>
					</ListItem>
				</List>
			</Drawer>
			<Container maxWidth="lg">
				<Toolbar>
					<Link to="/" className="logo">
						<img src={logo} alt="magpie" width="50" height="50"/>
					</Link>
					<div className="ml-auto">
						<Link to="/" className="nav-link">Home</Link>
						{/* <Link to="/pricing" className="nav-link">Business Intelligence</Link> */}
						<Link to="#" className="nav-link"
						onClick={navigateTo('https://www.magpieanalytics.com/business-intelligence-consulting-services/')}>Business Intelligence</Link>
						<Link to="#" className="nav-link"
						onClick={navigateTo('https://data.magpieanalytics.com/')}>Industry Analytics</Link>
						<Link to="#" className="nav-link"
						onClick={navigateTo('https://www.magpieanalytics.com/blog/')}>Blog</Link>
						<Link to="#" className="nav-link"
						onClick={navigateTo('https://www.magpieanalytics.com/magpie-about/')}>About Us</Link>
						<Link to="#" className="nav-link"
						onClick={navigateTo('https://www.magpieanalytics.com/magpie-contact/')}>Contact</Link>
					</div>
					<IconButton className="sidebar-trigger-mobile" onClick={(e)=>{serDrawerOpen(true)}}>
						<Menu />
					</IconButton>
				</Toolbar>
			</Container>
		</AppBar>
	);
}

export default Navbar