import { Card, Grid, CardContent, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import React from 'react'
import { Phone, Mail } from '@material-ui/icons'
const useStyles = makeStyles(theme => ({
	root: {
		marginTop: '30px',
		[theme.breakpoints.only('xs')]: {
			justifyContent: 'center'
		}
	},
	card: {
		textAlign: 'center',
		color: '#37364C'
	}
}))
function Support() {
	const classes = useStyles()
	return (
		<Box pl={1} pr={1}>
			<Grid container className={classes.root}>
				<Grid item xs={10} sm={6} md={5}>
					<Card className={classes.card}>
						<CardContent>
							<Phone fontSize="large" />
							<h2>Call Us</h2>
							<p>+88-01730725592</p>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			<Grid container className={classes.root}>
				<Grid item xs={10} sm={6} md={5}>
					<Card className={classes.card}>
						<CardContent>
							<Mail fontSize="large" />
							<h2>Email</h2>
							<p>maksudul@magpieanalytics.com</p>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	)
}

export default Support
