import React, {useContext, useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Home, Person, CreditCard, Phone, ExitToApp, Comment } from '@material-ui/icons';
import {Link, useHistory } from 'react-router-dom'
import {UserContext} from '../../context/UserContext'
import magpie from '../../assets/icons/magpie.png'
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		backgroundColor: '#FFD70F',
		border: 'none',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		backgroundColor: '#FFD70F',
		border: 'none',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(7) + 1,
		},
		'& .package': {
			fontSize: '1.5rem'
		}
	},
	drawerInfo: {
		padding: '10px 15px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	logo: {
		maxHeight: '60px',
		maxWidth: '60px',
		boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
		marginBottom: '15px',
		[theme.breakpoints.down('sm')]: {
			
		}
	},
	package: {
		fontSize: '1.5rem',
		color: '#EB5757',
		margin: 0,
		textTransform: 'capitalize'
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		[theme.breakpoints.down('xs')]: {
			display: 'none'
		}
	},
	sideLink: {
		textDecoration: 'none',
		color: '#37364C'
	}
}));

function SideBar({userData}) {
	const licenceName = userData?userData.user_license.assigned_license_name:""
	const {logOutUser} = useContext(UserContext)
	const classes = useStyles();
	const history = useHistory()
	const [open, setOpen] = useState(true);
	useEffect(()=>{
		// console.log(window.innerWidth)
		if(window.innerWidth<770){
			setOpen(false)
		}
	},[])
	const logOutUserHere = ()=>{
		logOutUser().then(response=>{
			history.push('/login')
		}).catch(error=>{
			history.push('/login')
		})
	}
	const handleDrawerClose = () => {
		setOpen(!open);
	};
	const menues = [
		{
			title: 'Home',
			path: '/',
			icon: <Home />
		},
		{
			title: 'Profile',
			path: '/profile',
			icon: <Person />
		},
		// {
		// 	title: 'Subscription',
		// 	path: '/subscription',
		// 	icon: <CreditCard />
		// },
		{
			title: 'Feedback',
			path: '/feedback',
			icon: <Comment />
		},
		{
			title: 'Support',
			path: '/support',
			icon: <Phone />
		}
	]
	return (
		<Drawer variant="permanent" className={clsx(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			})}
			classes={{
				paper: clsx({
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),}}>
			<div className={classes.toolbar}>
				<IconButton onClick={handleDrawerClose}>
					{open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
				</IconButton>
			</div>
			<Divider />
			<div className={classes.drawerInfo}>
					<Avatar alt="magpie" src={magpie} className={classes.logo} />
					{/* <h3 className={classes.package}>{licenceName}</h3> */}
				</div>
			<Divider/>
			<List>
				{menues.map((menu) => (
					<Link to={`/dashboard${menu.path}`} key={menu.title} className={classes.sideLink}>
						<ListItem button>
							<ListItemIcon>{menu.icon}</ListItemIcon>
							<ListItemText primary={menu.title} />
						</ListItem>
					</Link>
				))}
			</List>
			<Divider />
			<List>
				<ListItem button onClick={logOutUserHere}>
					<ListItemIcon><ExitToApp/></ListItemIcon>
					<ListItemText primary={"Log out"} />
				</ListItem>
			</List>
</Drawer>
	);
}

export default SideBar