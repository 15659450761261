import React, {useContext, useEffect } from 'react'
import LoggedInHome from './components/loggedin_components/LoggedInHome';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Login from './components/loggedout_components/Login'
import Signin from './components/loggedout_components/SignIn'
import MobileVerifier from './components/loggedout_components/MobileVerifier'
import PricingTable from './components/loggedout_components/PricingTable'
import HomePage from './components/loggedout_components/HomePage'
import Navbar from './components/loggedout_components/Navbar'
import Services from './components/loggedout_components/Services'
import Aboutus from './components/loggedout_components/Aboutus'
import PassReset from './components/loggedout_components/PassReset';
import {UserContext} from './context/UserContext'
import ContactUs from './components/loggedout_components/ContactUs';
import ResetPassword from './components/loggedout_components/RestPassword'
import firebase from './config/firebaseConfig'
import {CircularProgress} from '@material-ui/core'
function App() {
	const {userLoading} = useContext(UserContext)
	useEffect(()=>{
		if(!userLoading){
			firebase.analytics()
			console.log("...")
		}
	},[userLoading])

	if(userLoading){
		return (
		<div style={{height: '90vh' ,display:'flex', alignItems: 'center', justifyContent: 'center'}}>
			<CircularProgress />
		</div>
	)
	}else return (
		<div className="App">
			{/* Route the user according to the login status */}

			<Router>
				<Switch>
					<Route exact path="/">
						<Navbar />
						<HomePage />
					</Route>
					<Route path="/login">
						<Navbar />
						<Login />
					</Route>
					<Route path="/verify-email">
						<Navbar />
						<ResetPassword/>
					</Route>
					<Route path="/mobileverifier">
						<Navbar />
						<MobileVerifier />
					</Route>
					<Route path='/signin'>
						<Signin />
					</Route>
					<Route path="/pricing">
						<Navbar />
						<PricingTable />
					</Route>
					<Route path="/services">
						<Navbar />
						<Services />
					</Route>
					<Route path="/aboutus">
						<Navbar />
						<Aboutus />
					</Route>
					<Route path="/contactus">
						<Navbar />
						<ContactUs/>
					</Route>
					<Route path="/dashboard">
						<LoggedInHome/>
					</Route>
					<Route path='/password-reset'>
						<Navbar />
						<PassReset/>
					</Route>
				</Switch>
			</Router>
		</div>
	);
}

export default App;
