import axios from 'axios'
import React, { useContext, useState } from 'react'
import {UserContext} from '../../context/UserContext'
import {Button, Box} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom'


const useStyles = makeStyles((theme)=>({
	title: {
		fontSize: theme.spacing(4.5),
		color: '#EB5757',
		textTransform: 'capitalize'
	}
}))

function Subscription({userData, setUserData}) {
	const subscription = userData? userData.user_license : false
	const [disableBtn, setDiasableBtn] = useState(false)

	const history = useHistory()
	const classes = useStyles()
	const {refreshToken, getUserData } = useContext(UserContext)

	const tryProForFree = ()=>{
		setDiasableBtn(true)
		const config = {
			method: 'PATCH',
			url: 'https://magpie-webapp.azurewebsites.net/api/v1/user/apply-for-license/',
			withCredentials: true,
			data: {
				"applied_license": "pro",
				"applied_license_qt": 0
			}
		}
		axios(config).then(response=>{
			console.log('Trial successful', response)
			getUserData().then(_userData=>{
				setUserData(_userData)
				history.push('/dashboard')
			}).catch(error=>{
				// console.log(error)
				history.push('/dashboard')
			})
			// window.location.reload()
		}).catch(error=>{
			console.log('Error trying for pro ', error.response)
			refreshToken().then(response=>{
				// console.log(response)
				axios(config).then(response=>{
					// console.log(response)
					history.push('/dashboard')
				}).catch(error2=>{
					// console.log(error2)
					history.push('/login')
				})
			}).catch(error3=>{
				// console.log('Error refresh token from subscription ', error3)
				history.push('/login')
			})
		})
	}

	return (
		<Box p={1}>
			<div>
				{subscription?(
					<>
						<h2 className={classes.title}>{subscription.assigned_license_name}</h2>
						
						{!subscription.applied_for_pro?(
							<>
								<p>Take a trial of our powerful dashboard to see it's potential</p>
								<Button variant='contained' disabled={disableBtn} className="secondary-color" onClick={e=>{tryProForFree()}}>Try Pro For Free</Button>
							</>
						):(
							<>
								{subscription.assigned_license_name!=='free'?(
									<p>Your licence will expire on: {new Date(subscription.eat.toString()).toLocaleDateString()}</p>
								):""}
								<p>Contact us for new licence or renew your licence</p>
								<Button variant='contained' className="secondary-color" onClick={e=>{history.push('/dashboard/support')}}>Contact us for more</Button>
							</>
						)}
					</>)
				:""}
			</div>
		</Box>
	)
}

export default Subscription
