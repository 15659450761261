import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { StyleProvider } from './context/StyleContext';
import { UserProvider } from './context/UserContext'

ReactDOM.render(
  <StyleProvider>
    <UserProvider>
      <App />
    </UserProvider>
  </StyleProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// Tracking id : G-342SX99SQ8

// measurement id : G-NG6D2TQEQH