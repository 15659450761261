import { Container, Grid, Card, CardContent } from '@material-ui/core'
import React from 'react'
import { Phone, Mail } from '@material-ui/icons'
import {makeStyles} from '@material-ui/core/styles'
const useStyles = makeStyles(theme=>({
	root: {
		marginTop: '30px'
	},
	card: {
		textAlign: 'center',
		color: '#37364C'
	}
}))
function ContactUs() {
	const classes = useStyles()
	return (
		<Container maxWidth="lg" className={classes.root}>
			<Grid container spacing={4} justify="center">
				<Grid item sm={8} md={3} xs={12}>
					<Card className={classes.card}>
						<CardContent>
							<Phone fontSize="large" />
							<h2>Call Us</h2>
							<p>+88-01730725592</p>
						</CardContent>
					</Card>
				</Grid>
				<Grid item sm={8} md={3} xs={12}>
					<Card className={classes.card}>
						<CardContent>
							<Mail fontSize="large" />
							<h2>Email</h2>
							<p>maksudul@magpieanalytics.com</p>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Container>
	)
}

export default ContactUs
