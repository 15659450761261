import React, { useEffect, useState, useContext } from 'react'
import {useParams, useHistory} from 'react-router-dom'
import axios from 'axios'
import {UserContext} from '../../context/UserContext'
import {Box} from '@material-ui/core'


function SinglePublicDashboard() {
	const {id} = useParams()
	const { refreshToken } = useContext(UserContext)
	const [publicReportData, setPublicReportData] = useState({})
	const history = useHistory()
	const url = `https://magpie-webapp.azurewebsites.net/api/v1/user/dashboard/public-dashboard/${id}/`
	const access = window.localStorage.getItem('access')
	useEffect(()=>{
		const source = axios.CancelToken.source()
		const config = {
			method: 'GET',
			url: url,
			withCredentials: true,
			// cancelToken: source.token
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${access}`
			}
		} 
		axios(config).then(response=>{
			// console.log(response.data)
			setPublicReportData(response.data)
		}).catch(error=>{
			// console.log(error)
			if(error.response?.status === 401){
				refreshToken().then(response=>{
					axios(config).then(response=>{
						// console.log(response.data)
						setPublicReportData(response.data)
						// console.log(reportData)
					}).catch(error2=>{
						// console.log(error2)
						history.push("/dashboard")
					})
				}).catch(error=>{
					console.log(error)
					history.push("/dashboard")
				})
			}
		})
		return () => source.cancel('Request canceled')
	},[refreshToken,id , setPublicReportData, history])
	return (
		<Box pl={1} pr={1} pt={1} pb={1}>
			{publicReportData.id?(
				<div style={{height: '90vh'}}>
					<iframe src={publicReportData.dashboard_url}
					 width="100%" height="100%" 
					 frameborder="0" 
					 allowFullScreen="true"
					 ></iframe>
				</div>
			):""}
		</Box>
	)
}

export default SinglePublicDashboard
