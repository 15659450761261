import { Grid, TextField, Box, Button } from '@material-ui/core'
import React, {useState, useContext} from 'react'
import axios from 'axios'
import {UserContext} from '../../context/UserContext'
import { useHistory } from 'react-router-dom'
import {Alert} from '@material-ui/lab'

function Feedback() {
	const [subject, setSubject] = useState("")
	const [feedback, setFeedback] = useState("")
	const [isWaiting, setIsWaiting] = useState(false)
	const [feedbackSendStatus, setFeedbackSendStatus] = useState("")
	const { refreshToken } = useContext(UserContext)
	const history = useHistory()
	const handleSubmit = (e)=>{
		e.preventDefault();
		// console.log(subject, feedback)
		// call api here
		if(subject!=="" && feedback!==""){
			setIsWaiting(true)
			const access = window.localStorage.getItem('access')
			const config = {
				method: 'POST',
				url: 'https://magpie-webapp.azurewebsites.net/api/v1/user/feedback/',
				withCredentials: true,
				data: {
					subject: subject,
					description: feedback
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${access}`
				}
			}
			axios(config).then(response=>{
				setFeedbackSendStatus("Feedback send successfully. Thank you for your valuable feedback")
				setIsWaiting(false)
				setSubject("")
				setFeedback("")
			}).catch(error=>{
				console.log(error.response)
				if(error.response.status===401){
					refreshToken().then(response=>{
						axios(config).then(response=>{
							setFeedbackSendStatus("Feedback send successfully. Thank you for your valuable feedback")
							setIsWaiting(false)
							setSubject("")
							setFeedback("")
						}).catch(error=>{
							setIsWaiting(false)
							console.log(error)
							history.push('/login')
						})
					}).catch(error=>{
						setIsWaiting(false)
						console.log(error)
						history.push('/login')
					})
				}else{
					setIsWaiting(false)
					console.log(error)
				}
			})
		}else{
			console.log('Fill the field')
		}
	}
	return (
		<Box p={2}>
			<Grid container spacing={2}>
				<Grid item lg={6} md={6} sm={8} xs={12}>
					<h2 className="f-primary-title">Leave your valuable Feedback to improve our services</h2>
					{feedbackSendStatus!==""?(
						<Alert>{feedbackSendStatus}</Alert>
					):""}
					<form onSubmit={handleSubmit}>
						<Box mt={2} mb={2}>
							<TextField required type="text" variant="outlined" fullWidth label="Subject" placeholder="Subject" value={subject} onChange={e=>{setSubject(e.target.value)}}/>
						</Box>
						<Box mt={2} mb={2}>
							<TextField required type="text" multiline rows={4} variant="outlined" fullWidth label="Your comments" placeholder="Your comments" value={feedback} onChange={e=>{setFeedback(e.target.value)}}/>
						</Box>
						<Box display="flex" justifyContent="flex-end">
							<Button variant="contained" className="secondary-color" disabled={isWaiting} onClick={e=>{history.goBack()}}>Cancel</Button>
							<Box ml={2}>
								<Button variant="contained" type="submit" className="primary-color" disabled={isWaiting}>Submit</Button>
							</Box>
						</Box>
					</form>
				</Grid>
			</Grid>
		</Box>
	)
}

export default Feedback
