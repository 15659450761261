import React, { useState, useContext } from 'react'
import { Box, Button, Grid, TextField, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Snackbar } from '@material-ui/core'
// import { Edit } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { UserContext } from '../../context/UserContext'
import { Alert } from '@material-ui/lab'
const useStyle = makeStyles((theme) => ({
	container: {
		color: '#37364C'
	},
	title: {
		fontSize: theme.spacing(4.5)
	},
	user_i_table: {
		'& tbody tr': {
			lineHeight: "2rem",
			'& .spacer': {
				minWidth: '1rem',
				textAlign: 'center'
			}
		}
	}
}))

function UserProfile({ userData, setUserData }) {
	const [oldPassword, setOldPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [showEditField, setShowEditField] = useState(false)
	const [newOrgName, setNewOrgName] = useState("")
	const [newAddress, setNewAddress] = useState("")
	const [reEnterPassword, setReEnterPassword] = useState('')
	const { refreshToken } = useContext(UserContext)
	const [promptMsg, setPromptMsg] = useState({ msg: "", type: "success" })
	const [changeOnProgress, setChangeOnProgress] = useState(false)
	const [snackBarOpen, setSnackBarOpen] = useState(false)
	const classes = useStyle()
	
	
	const handleSnackbarClose = (event, reason) => {
		if(reason === 'clickaway') {
		  return;
		}
		setSnackBarOpen(false);
	  };
	const handleSubmit = (e) => {
		e.preventDefault()

		if (newPassword !== '' && oldPassword !== '') {
			if (newPassword === reEnterPassword) {
				setChangeOnProgress(true)
				const data = {
					old_password: oldPassword,
					new_password1: newPassword,
					new_password2: reEnterPassword
				}
				const access = window.localStorage.getItem('access')
				const config = {
					method: 'POST',
					url: 'https://magpie-webapp.azurewebsites.net/api/v1/password/change/',
					data: data,
					withCredentials: true,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${access}`
					}
				}
				axios(config).then(response => {
					// console.log('password changed', response)
					setNewPassword("")
					setOldPassword("")
					setReEnterPassword("")
					setPromptMsg({ msg: "Password changed", type: "success" })
					setSnackBarOpen(true)
					setChangeOnProgress(false)
				}).catch(error => {
					console.log('Error change password', error)
					if (error.response.status === 401) {
						refreshToken().then(response => {
							axios(config).then(response => {
								// console.log('password changed', response)
								setNewPassword("")
								setOldPassword("")
								setReEnterPassword("")
								setPromptMsg({ msg: "Password changed", type: "success" })
								setSnackBarOpen(true)
								setChangeOnProgress(false)
							}).catch(error => {
								setPromptMsg({ msg: "Wrong password. Check your old password", type: "error" })
								setChangeOnProgress(false)
								setSnackBarOpen(true)
							})
						}).catch(error => {
							setPromptMsg({ msg: "Wrong password. Check your old password", type: "error" })
							setSnackBarOpen(true)
							setChangeOnProgress(false)

						})
					} else {
						setChangeOnProgress(false)
						setSnackBarOpen(true)
						setPromptMsg({ msg: "Wrong password. Check your old password", type: "error" })
					}
				})
			} else {
				setSnackBarOpen(true)
				setPromptMsg({ msg: "Confirm password doesn't match", type: "error" })
			}
		} else {
			setSnackBarOpen(true)
			setPromptMsg({ msg: "Please fill all fields", type: "error" })
		}
	}
	
	
	const makeUpdate = (e)=>{
		// e.preventDefault()
		// console.log(newOrgName, newAddress)
		const data = {
			org_name: newOrgName,
			address: newAddress
		}
		const access = window.localStorage.getItem('access')
		const config = {
			method: 'PUT',
			withCredentials: true,
			url:'https://magpie-webapp.azurewebsites.net/api/v1/user/profile/',
			data: data,
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${access}`
			}
		}
		if(newOrgName!=="" && newAddress!==""){
			axios(config).then(response=>{
				// setPromptMsg({ msg: "Profile updated", type: "success" })
				setUserData(response.data)
				handleClose()
				setSnackBarOpen(true)
				console.log(response.data)
			}).catch(error=>{
				console.log(error.response)
				if (error.response.status === 401) {
					refreshToken().then(response => {
						axios(config).then(response => {
							// setPromptMsg({ msg: "Profile updated", type: "success" })
							setUserData(response.data)
							handleClose()
							setSnackBarOpen(true)
							console.log(response.data)
						}).catch(error => {
							console.log(error.response)
							setPromptMsg({ msg: "Can't update profile now. Try later", type: "error" })
						})
					}).catch(error => {
						console.log(error.response)
						setPromptMsg({ msg: "Can't update profile now. Try later", type: "error" })
						handleClose()
					})
				} else {
					setPromptMsg({ msg: "Can't update profile now. Try later", type: "error" })
					handleClose()
				}
			})
		}else{
			handleClose()
		}
	}
	const handleClose = () => {
		setShowEditField(false)
	}
	return (
		<Grid container>
			<Box p={1}>
			<div>
				<Dialog open={showEditField} maxWidth="sm" fullWidth  aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">Organization Name</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Chnage your organization name
          				</DialogContentText>
						<form>
							<TextField
								autoFocus
								margin="dense"
								label="Address"
								type="text"
								fullWidth
								value={newAddress}
								onChange={(e) => { setNewAddress(e.target.value) }}
							/>
							<TextField
								margin="dense"
								label="Organization Name"
								type="text"
								fullWidth
								value={newOrgName}
								onChange={(e) => { setNewOrgName(e.target.value) }}
							/>
						</form>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancel
          				</Button>
						<Button onClick={makeUpdate} color="primary">
							Change
          				</Button>
					</DialogActions>
				</Dialog>
				<Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
					<Alert onClose={handleSnackbarClose} severity={promptMsg.type? promptMsg.type:"success"}>
						{promptMsg.msg?promptMsg.msg:'Your profile has been updated'}
					</Alert>
				</Snackbar>
			</div>
			<Grid item md={6} sm={8} xs={12} className={classes.container}>
				<h2 className={classes.title}>User Profile</h2>
				{userData ? (<div>
					<table className={classes.user_i_table}>
						<tbody>
							<tr>
								<td><strong>Name</strong></td>
								<td className={"spacer"}> : </td>
								<td>{userData.user.name ? userData.user.name : "No data"}</td>
							</tr>
							<tr>
								<td><strong>Email</strong></td>
								<td className={"spacer"}> : </td>
								<td>{userData.user.email ? userData.user.email : "No data"}</td>
							</tr>
							<tr>
								<td><strong>Mobile</strong></td>
								<td className={"spacer"}> : </td>
								<td>{userData.phone_no ? userData.phone_no : "No data"}</td>
							</tr>
							<tr>
								<td><strong>Address</strong></td>
								<td className={"spacer"}> : </td>
								<td>{userData.address ? userData.address : "No data"}</td>
							</tr>
							<tr>
								<td><strong>Bank acc no</strong></td>
								<td className={"spacer"}> : </td>
								<td>{userData.bank_acc ? userData.bank_acc : "No data"}</td>
							</tr>
							<tr>
								<td><strong>Organization name</strong></td>
								<td className={"spacer"}> : </td>
								<td>
									{userData.org_name ? userData.org_name : "No data"}
								</td>
							</tr>
						</tbody>
					</table>
					<Button onClick={e => { setShowEditField(!showEditField) }} className={'secondary-color'} variant="contained">
								Edit profile
						</Button>
				</div>) : <span></span>}
				<h2 className={classes.title}>Change password</h2>
				<form onSubmit={handleSubmit}>
					<TextField required className="mb-2" type="password" variant="outlined" fullWidth label="Old password" placeholder="password" value={oldPassword} onChange={e => { setOldPassword(e.target.value) }} />
					<TextField required className="mb-2" type="password" variant="outlined" fullWidth label="New password" placeholder="password" value={newPassword} onChange={e => { setNewPassword(e.target.value) }} />
					<TextField required className="mb-2" type="password" variant="outlined" fullWidth label="Re-enter password" placeholder="password" value={reEnterPassword} onChange={e => { setReEnterPassword(e.target.value) }} />
					<Button disabled={changeOnProgress} type="submit" className={'secondary-color'} variant="contained">Update password</Button>
				</form>
				{/* {promptMsg.msg ? (
					<Box mt={3}>
						<Alert severity={promptMsg.type}>{promptMsg.msg}</Alert>
					</Box>
				) : ""} */}
			</Grid>
			</Box>
		</Grid>
	)
}

export default UserProfile
