import React, { useState, useContext, useEffect } from 'react'
import SideBar from './SideBar'
import { makeStyles } from '@material-ui/core/styles'
import UserProfile from './UserProfile';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import Subscription from './Subscription';
// import Dashboards from './Dashboards'
import PublicDashboards from './PublicDashboards';
// import SingleDashboard from './SingleDashboard';
import SinglePublicDashboard from './SinglePublicDashboard';
import Support from './Support';
import { useRouteMatch } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import Feedback from './Feedback'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	content: {
		flexGrow: 1,
		paddingLeft: theme.spacing(0),
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(0),
		}
	},
}));

function LoggedInHome() {
	const [userData, setUserData] = useState()
	const history = useHistory()
	const classes = useStyles()
	let { path } = useRouteMatch();
	const { isLoggedIn, getUserData } = useContext(UserContext)

	useEffect(() => {
		getUserData().then((_userData) => {
			setUserData(_userData)
		}).catch(error => {
			console.log('error form user profile', error)
			history.push('/login')
		})
	}, [history, getUserData])


	if (!isLoggedIn) {
		return <Redirect to="/login" />
	}
	else return (
		<div className={classes.root}>
			<Router>
				<SideBar userData={userData} />
				<main className={classes.content}>
					<Switch>
						{/* <Route exact path={path}>
							<Dashboards />
						</Route> */}
						<Route exact path={path}>
							<PublicDashboards />
						</Route>
						{/* <Route path={`${path}/subscription`}>
							<Subscription userData={userData} setUserData={setUserData} />
						</Route> */}
						<Route path={`${path}/profile`} >
							<UserProfile userData={userData} setUserData={setUserData}/>
						</Route>
						<Route path={`${path}/support`}>
							<Support />
						</Route>
						<Route path={`${path}/feedback`}>
							<Feedback/>
						</Route>
						{/* <Route path={`${path}/report/:id`}>
							<SingleDashboard />
						</Route> */}
						<Route path={`${path}/report/:id/`}>
							<SinglePublicDashboard />
						</Route>
					</Switch>
				</main>
			</Router>
		</div>
	)
}

export default LoggedInHome
