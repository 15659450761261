import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import '../../styles/mobile_verifier.scss'
import { UserContext } from '../../context/UserContext'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import { Button, Container, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, FormControl, Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

function RestPassword() {
	const history = useHistory()
	const { isLoggedIn } = useContext(UserContext)
	var { email, setEmail } = useContext(UserContext)
	const { phone_no, setPhone_no } = useContext(UserContext)
	const [userEmail, setUserEmail] = useState("")
	const [otp, setOpt] = useState("")
	const [errorMsg, setErrorMsg] = useState()
	const [disableBtn, setDisableBtn] = useState(false)
	const [dialogOpen, setDialogOpen] = useState(false)
	const handleSubmit = (e)=>{
		e.preventDefault();
		setDisableBtn(true)
		setEmail(userEmail)
		email = userEmail
		const config = {
			method: 'POST',
			url: 'https://magpie-webapp.azurewebsites.net/api/v1/password-reset/init/',
			data: {
				phone_no: userEmail
			  }
		}
		axios(config).then(response=>{
			// console.log(response.data.message)
			setErrorMsg({msg:response.data.message, type: 'success'})
			setPhone_no(response.data.phone_no)
			setDisableBtn(false)
			setTimeout(()=>{setDialogOpen(true)}, 2000)
		}).catch(error=>{
			// console.log(error)
			setErrorMsg({msg:error.response.data.message, type: 'error'})
			setDisableBtn(false)
		})
	}
	const handleConfirmation = () => {
		setDisableBtn(false)
		const url = 'https://magpie-webapp.azurewebsites.net/api/v1/password-reset/verify/'

		if(otp !== ""){
			axios({
				method: 'POST',
				url: url,
				data: {
					phone_no: userEmail,
					otp_code: otp
				}
			}).then(response=>{
				setDialogOpen(false)
				setErrorMsg({msg:response.data.message, type: 'success'})
				setTimeout(()=>{history.push('/password-reset')}, 1500)
			}).catch(error=>{
				setDialogOpen(false)
				setErrorMsg({msg:error.response.data.message, type: 'error'})
				if(error.response.status === 404){
					setErrorMsg({msg:error.response.data.message, type: 'error'})
				}
			})
		} else{
			alert("Please enter OTP")
		}
	}

	if (isLoggedIn) {
		return <Redirect to="/dashboard" />
	} else return (
		<Container maxWidth="lg" className="signup-container">
			<Box mt={3}>
				<Grid container justify="center">
					<Grid item lg={4} md={6} sm={8}>
						{errorMsg?(
							<Alert severity={errorMsg.type}>{errorMsg.msg}</Alert>
						):<span></span>}
					</Grid>
				</Grid>
			</Box>
			<div className="mobile-verifier-body">
				<Grid container justify="center">
					<Grid item lg={4} md={6} sm={8}>
						<div>
							<h3 className="title">Enter Phone Number to Continue</h3>
							<form onSubmit={handleSubmit}>
								<TextField className="mb-2" variant="outlined" type="text" fullWidth label="Phone Number" placeholder="+8801234567890" required value={userEmail} onChange={(e)=>{setUserEmail(e.target.value)}}/>
								<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
									<Button className="secondary-color" variant="contained" type="submit" disabled={disableBtn}>
										Reset password
									</Button>
								</div>
							</form>
						</div>
					</Grid>
				</Grid>
			</div>
			<Dialog open={dialogOpen} onClose={() => { setDialogOpen(false) }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">OTP verification</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please Verify the OTP Sent to the Number to Continue with Magpie Analytics</DialogContentText>
                    <TextField margin="dense" id="top" label="Phone Number" value={userEmail} type="text" fullWidth  disabled/>
                    <TextField autoFocus margin="dense" id="top1" label="OTP code" type="text" fullWidth onChange={e => { setOpt(e.target.value) }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDialogOpen(false) }} color="secondary">Cancel</Button>
                    <Button color="primary" onClick={handleConfirmation}>Verify</Button>
                </DialogActions>
            </Dialog>
		</Container>
	)
}

export default RestPassword
