import React, { useState, useEffect, useContext } from 'react'
import { Box, Grid, Badge } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link, useRouteMatch, Redirect,useHistory } from 'react-router-dom'
import axios from 'axios'
import { UserContext } from '../../context/UserContext'
const useStyles = makeStyles((theme) => ({
	image: {
		width: '100%'
	},
	dashboardCard: {
		padding: theme.spacing(0),
		boxShadow: '0px 10px 25px rgba(0,0,0,0.1)'
	},
	dashboardText: {
		padding: '20px'
	},
	link: {
		textDecoration: 'none',
		color: '#37364C'
	},
	title: {
		fontSize: theme.spacing(3.75),
		margin: '0 0 10px 0'
	}
}))
function PublicDashboards() {
	const [publicdashboards, setPublicDashboards] = useState([])
	const { path } = useRouteMatch()
	const { isLoggedIn, refreshToken } = useContext(UserContext)
	const history = useHistory()
	const classes = useStyles()
	const url = 'https://magpie-webapp.azurewebsites.net/api/v1/user/dashboard/public-dashboards/'
	useEffect(() => {
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();
		const access = localStorage.getItem('access')
		if (isLoggedIn) {
			var config = {
				method: 'get',
				withCredentials: true,
				url: url,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${access}`
				}
			};
			axios(config).then(response => {
				// console.log(response.data)
				setPublicDashboards(response.data)
			}).catch(error => {
				// console.log(error)
				if(error.response?.status === 401){
					// console.log("Not authinticated")
					refreshToken().then(token=>{
						// console.log(token)
						axios(config).then(response=>{
							setPublicDashboards(response.data)
						}).catch(error=>{
							history.push('/login')
						})
					}).catch(msg=>{
						console.log(msg)
						history.push('/login')
					})
				}
			})
		}
		return () => source.cancel()
	},[isLoggedIn, setPublicDashboards, refreshToken, history])
	if(!isLoggedIn){
		return <Redirect to="/login"/>
	}
	return (
		<Box pt={2} pb={2} pr={1} pl={1}>
		<Grid container spacing={2}>
			{publicdashboards.map(dsb => (
				<Grid item lg={6} md={6} sm={12} xs={12} key={dsb.title}>
					<Link to={`${path}/report/${dsb.id}/`} className={classes.link}>
						<div className={classes.dashboardCard}>
							<img className={classes.image} src={dsb.thumbnail_url} alt={dsb.title} />
							<div className={classes.dashboardText}>
								<Badge color="secondary">
									<h3 className={classes.title}>{dsb.title}</h3>
								</Badge>
							</div>
						</div>
					</Link>
				</Grid>
			))}
		</Grid>
		</Box>
	)
}

export default PublicDashboards
