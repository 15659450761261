import React from 'react'
import { Button, Container, Grid } from '@material-ui/core'
import '../../styles/pricing_table.scss'
import account_cog from '../../assets/icons/account-cog.svg'
import auto_fix from '../../assets/icons/auto-fix.svg'
import codepen from '../../assets/icons/codepen.svg'
import {useHistory} from 'react-router-dom'

function PricingTable() {
	const history = useHistory()

	const pushToPage = (route)=>{
		history.push(route)
	}
	return (
		<Container maxWidth="lg">
			<h2 className="pricing-table-title">Choose The Right Solution For You</h2>
			<Grid container spacing={3} alignItems="stretch">
				<Grid item lg={4} xl={4} md={4} sm={6} xs={12}>
					<div className="pricing-table dull">
						<div className="pricing-table-header">
							<h2>Free</h2>
						</div>
						<div className="pricing-table-header-body">
							<h2 className="body-title">Free</h2>
						</div>
						<div className="pricing-table-body">
							<p>
								<img src={account_cog} alt="icon" />
								Checkout two Dashboards
							</p>
							<p>
								<img src={auto_fix} alt="icon" />
								Experience the State of the Art
							</p>
							<p>
								<img src={codepen} alt="icon" />
								Conduct lightweight Research
							</p>
						</div>
						<div className="pricing-table-footer">
							<Button variant="outlined" className="btn outline-btn" onClick={(e)=>{pushToPage('/mobileverifier')}}>Register</Button>
						</div>
					</div>
				</Grid>
				<Grid item lg={4} xl={4} md={4} sm={6} xs={12}>
					<div className="pricing-table primary">
						<div className="pricing-table-header">
							<h2>Pro</h2>
						</div>
						<div className="pricing-table-header-body">
							<h2 className="body-title">BDT 2000</h2>
							<p>Per user/month</p>
						</div>
						<div className="pricing-table-body">
							<p>
								<img src={account_cog} alt="icon" />
								For individuals an Small Teams
							</p>
							<p>
								<img src={auto_fix} alt="icon" />
								Covers Advanced Geospatial Analytics
							</p>
							<p>
								<img src={codepen} alt="icon" />
								Conduct In-Depth Analysis
							</p>
						</div>
						<div className="pricing-table-footer">
							<Button variant="contained" className="btn primary-btn" onClick={(e)=>{pushToPage('/mobileverifier')}}>Try Pro For Free</Button>
							<Button className="btn link-btn" onClick={(e)=>{window.open('https://www.magpieanalytics.com/magpie-about/', '_blank')}}>Learn more</Button>
						</div>
					</div>
				</Grid>
				<Grid item lg={4} xl={4} md={4} sm={6} xs={12}>
					<div className="pricing-table dark">
						<div className="pricing-table-header">
							<h2>Pro</h2>
						</div>
						<div className="pricing-table-header-body">
							<h2 className="body-title">Contact US</h2>
						</div>
						<div className="pricing-table-body">
							<p>
								<img src={account_cog} alt="icon" />
								For Large Teams or Focused use
							</p>
							<p>
								<img src={auto_fix} alt="icon" />
								Customizable Solution
							</p>
							<p>
								<img src={codepen} alt="icon" />
								Access to Raw Data
							</p>
						</div>
						<div className="pricing-table-footer">
							<Button variant="outlined" className="btn outline-btn" onClick={(e)=>{window.open('https://www.magpieanalytics.com/magpie-contact/', '_blank')}}>Contact us</Button>
							<Button className="btn link-btn" onClick={(e)=>{window.open('https://www.magpieanalytics.com/magpie-about/', '_blank')}}>Learn more</Button>
						</div>
					</div>
				</Grid>
			</Grid>
		</Container>
	)
}

export default PricingTable
