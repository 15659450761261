import { Container, Grid, TextField, Button } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import '../../styles/mobile_verifier.scss'
import { UserContext } from '../../context/UserContext'
import { StyleContext } from '../../context/StyleContext'
import { Redirect, useHistory } from 'react-router-dom'
import axios from 'axios'

function Signin() {
	const [fullName, setFullName] = useState("")
	const { isLoggedIn, setIsLoggedIn } = useContext(UserContext)
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [confirmPassword, setConfirmPassword] = useState("")
	const [singInError, setSignInError] = useState("")
	const [btnDisable, setBtnDisable] = useState(false)
	const history = useHistory()
	const { mobileVerified, phone_no } = useContext(UserContext)
	const { message, setMessage } = useContext(StyleContext)
	
	const handleSubmit = (e) => {
		e.preventDefault();
		const pattren = /^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)[0-9a-zA-Z]{8,}$/
		if (email !== "" && fullName !== "" && password !== "") {
			if (password.match(pattren)) {
				if (password === confirmPassword) {
					const url = "https://magpie-webapp.azurewebsites.net/api/v1/registration/"
					const data = {
						"email": email,
						"name": fullName,
						"password": password,
						"phone_no": phone_no
					}
					setBtnDisable(true)
					axios({
						method: "POST",
						url: url,
						withCredentials: true,
						data: data
					}).then((response) => {
						localStorage.setItem('access', response.data.access_token)
						localStorage.setItem('refresh', response.data.refresh_token)
						setIsLoggedIn(true)
						setMessage({})
						// ls.set('rt', response.data.refresh_token)
						history.push("/dashboard")
					}).catch(error => {
						setBtnDisable(false)
						// console.log(error.response.status)
						if(error.response.status===500){
							setSignInError("Email is already used")
						}else{
							setSignInError("Error signing in")
						}
						
					})
				} else {
					setBtnDisable(false)
					setSignInError("Confirm password doesn't match")
				}
			} else {
				setBtnDisable(false)
				setSignInError("Please Enter a Valid Password")
			}
		} else {
			setBtnDisable(false)
			setSignInError("Please fill all fields")
		}
	}
	if (!mobileVerified) {
		return (<Redirect to="/mobileverifier" />)
	} else return (
		<Container maxWidth="lg" className="signup-container">
			<div className="mobile-verifier-body">
				<Grid container justify="center">
					<Grid container item lg={4} md={6} sm={8}>
						<div>
							<h3 className="title">Sign up for new account</h3>
							{singInError ? (
								<div style={{ padding: '10px 20px', backgroundColor: '#ffbaba', color: '#37364C', marginBottom: '20px', borderRadius: '5px' }}>
									<p>{singInError}</p>
								</div>
							) : ""}
							<form onSubmit={handleSubmit}>
								<TextField className="mb-2" variant="outlined" required type="text" fullWidth label="Phone Number" placeholder="Phone Number" value={phone_no} disabled/>
								<TextField className="mb-2" variant="outlined" required type="text" fullWidth label="Full name" placeholder="Name" onChange={(e) => { setFullName(e.target.value) }} />
								<TextField className="mb-2" variant="outlined" required type="email" fullWidth label="Email" placeholder="your email" onChange={(e) => { setEmail(e.target.value) }} />
								<TextField className="mb-2" type="password" variant="outlined" required fullWidth label="Password" placeholder="At least 8 charecter" helperText="At least 8 charecter must contains one number and alphabet" onChange={(e) => { setPassword(e.target.value) }} />
								<TextField className="mb-2" type="password" variant="outlined" required fullWidth label="Confirm password" placeholder="At least 8 charecter" onChange={(e) => { setConfirmPassword(e.target.value) }} />
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Button className="primary-color" variant="contained" style={{ marginRight: '10px' }} disabled={btnDisable} onClick={e => { history.push('/') }}>Cancel</Button>
									<Button className="secondary-color" variant="contained" type="submit" disabled={btnDisable}>Sign up</Button>
								</div>
							</form>
						</div>
					</Grid>
				</Grid>
			</div>
		</Container>
	)
}

export default Signin
