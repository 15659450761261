import { Container, Grid, TextField, Button, CircularProgress, Box } from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import React, { useContext, useState } from 'react'
import '../../styles/mobile_verifier.scss'
import { UserContext } from '../../context/UserContext'
import { StyleContext } from '../../context/StyleContext'
import axios from 'axios'
import { Redirect, useHistory, Link } from 'react-router-dom'



function Login() {
	const { isLoggedIn, setIsLoggedIn } = useContext(UserContext)
	const [waitForLogIn, setWaitForLogIn] = useState(false)
	const { message, setMessage } = useContext(StyleContext)
	const [logInError, setLogInError] = useState("")
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const history = useHistory()
	const handleSubmit = (e) => {
		e.preventDefault();
		const url = "https://magpie-webapp.azurewebsites.net/api/v1/login/"
		// const pattren = /^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)(?=.*[!@#$%^&*]+.*)[a-zA-Z0-9!@#$%^&*]{8,}$/
		if (email !== "" && password !== "") {
			const config = {
				method: "POST",
				withCredentials: true,
				url: url,
				data: {
					phone_no: email, // Here email is actually phone number
					password: password
				}
			}
			axios(config).then((response) => {
				// console.log(response.data)
				
				localStorage.setItem('access', response.data.access_token)
				localStorage.setItem('refresh', response.data.refresh_token)
				setIsLoggedIn(true)
				setMessage({})
				history.push("/dashboard")
			}).catch(error => {
				// console.log(error.response)
				if (error.response.data.non_field_errors) {
					setLogInError(error.response.data.non_field_errors[0])
				} else {
					setLogInError("Error log in")
				}
				setWaitForLogIn(false)
			})
		} else {
			setLogInError("Please check inputs")
			setWaitForLogIn(false)
		}
	}
	if (isLoggedIn) {
		return <Redirect to="/dashboard" />
	} else return (
		<Container maxWidth="lg" className="signup-container">
			{message.message ? (
				<Box mt={4}>
					<Grid container justify="center" className="mt3">
						<Grid item lg={4} md={6} sm={8}>
							<Alert severity="error">{message.message}</Alert>
						</Grid>
					</Grid>
				</Box>
			) : <span></span>}
			<div className="mobile-verifier-body">
				<Grid container justify="center">
					<Grid container item lg={4} md={6} sm={8}>
						<div>
							<h3 className="title">Log In with Existing Account</h3>
							<form onSubmit={handleSubmit}>
								<TextField className="mb-2" variant="outlined" type="text" fullWidth label="Phone Number" placeholder="+8801234567890" error={logInError !== ""} onChange={(e) => { setEmail(e.target.value) }} />
								<TextField className="mb-2" type="password" variant="outlined" fullWidth label="Password (min 8 alpha numeric)" placeholder="At least 8 charecter" error={logInError !== ""} helperText={logInError} onChange={(e) => { setPassword(e.target.value) }} />
								<div style={{ display: 'flex', justifyContent: 'flex-end'}}>
									{/* <span>
										No account? <Link to="/mobileverifier">Sign up</Link> for free.
									</span> */}
									<Button fullWidth className="secondary-color" onClick={(e)=>{history.push('/mobileverifier')}}>Sign up</Button>
									<Box width={20}></Box>
									<Button fullWidth className="primary-color" variant="contained" type="submit" onClick={(e) => { setWaitForLogIn(true) }}>
										Login
										{waitForLogIn ? <CircularProgress size={20} /> : ""}
									</Button>
									<Box width={20}></Box>
								</div>
							</form>
							<Box height={20}></Box>
							<span>
								Forgot Password? <Link to="/verify-email">Reset Password</Link>
							</span>
						</div>
					</Grid>
				</Grid>
			</div>
		</Container>
	)
}

export default Login
