import React, { useState, useContext } from 'react'
import { UserContext } from '../../context/UserContext'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import { Container, Grid, TextField, Button, Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'

function PassReset() {
    const history = useHistory()
    const { isLoggedIn, setIsLoggedIn } = useContext(UserContext)
    const { email } = useContext(UserContext)
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [alertMsg, setAlertMsg] = useState()
    const [alertType, setAlertType] = useState()
	const [disableBtn, setDisableBtn] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisableBtn(true)
        if (newPassword === confirmPassword) {
            const config = {
                method: 'POST',
                url: 'https://magpie-webapp.azurewebsites.net/api/v1/password-reset/complete/',
                data: {
                    phone_no: email,
                    new_password: newPassword
                }
            }
            axios(config).then(response => {
                // console.log(response.data.detail)
                setAlertMsg('Password Reset Successful, Redirecting to Login Page')
                setAlertType('success')
                setDisableBtn(false)
                setTimeout(()=> {
                    history.push('/login')
                }, 2000)
            }).catch(error => {
                // console.log(error)
                setAlertMsg(error.response.data.message)
                setAlertType('error')
                setDisableBtn(false)
            })
        } else {
            setAlertMsg('Password Mismatch')
            setAlertType('error')
            setDisableBtn(false)
        }
    }
    if (isLoggedIn) {
        return <Redirect to='/dashboard' />
    } else return (
        <Container maxWidth="lg" className="signup-container">
			{alertMsg ? (
				<Box mt={4}>
					<Grid container justify="center" className="mt3">
						<Grid item lg={4} md={6} sm={8}>
							<Alert severity={alertType}>{alertMsg}</Alert>
						</Grid>
					</Grid>
				</Box>
			) : <span></span>}
			<div className="mobile-verifier-body">
				<Grid container justify="center">
					<Grid container item lg={4} md={6} sm={8}>
						<div>
							<h3 className="title">Enter New Password</h3>
							<form onSubmit={handleSubmit}>
								<TextField className="mb-2" variant="outlined" type="text" fullWidth label="Phone Number" value={email} disabled />
								<TextField className="mb-2" type="password" variant="outlined" fullWidth label="Password (min 8 alpha numeric)" placeholder="At least 8 charecter" onChange={(e) => { setNewPassword(e.target.value) }} />
								<TextField className="mb-2" type="password" variant="outlined" fullWidth label="Confirm Password" placeholder="At least 8 charecter" onChange={(e) => { setConfirmPassword(e.target.value) }} />
								<div style={{ display: 'flex', justifyContent: 'flex-end'}}>
									<Button fullWidth className="secondary-color" onClick={(e)=>{history.push('/login')}}>Cancel</Button>
									<Box width={20}></Box>
									<Button fullWidth className="primary-color btn" variant="contained" type="submit" disabled={disableBtn}>
                                        Update password
                                    </Button>
								</div>
							</form>
						</div>
					</Grid>
				</Grid>
			</div>
		</Container>
    )
}

export default PassReset;