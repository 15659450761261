import React, {createContext, useState, useEffect} from 'react'
import axios from 'axios'

export const UserContext = createContext()

export function UserProvider(props) {
	const [user,setUser] = useState(null)
	const [userLoading, setUserLoading] = useState(true)
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [mobileVerified, setMobileVerified] = useState(!false)
	const [userMobileNumber, setUserMobileNumber] = useState("")
	const [phone_no, setPhone_no] = useState("")
	var [email, setEmail] = useState("")
	const logOutUser = ()=>{
		return new Promise((resolve, reject)=>{
			axios({
				method: "POST",
				url: 'https://magpie-webapp.azurewebsites.net/api/v1/logout/',
				withCredentials: true,
			}).then(response=>{
				// console.log("Log out successful ", response)
				setUser({})
				setIsLoggedIn(false)
				setUserLoading(false)
				localStorage.removeItem('access')
				localStorage.removeItem('refresh')
				resolve({msg: 'Log out Successful'})
			}).catch(error=>{
				if(error.response.status === 401){
					refreshToken().then(response=>{
						// console.log("Log out successful ", response)
						setUser({})
						setIsLoggedIn(false)
						setUserLoading(false)
						localStorage.removeItem('access')
						localStorage.removeItem('refresh')
						resolve({msg: 'Log out Successful'})
					}).catch(error=>{
						// console.log("Error login ",error)
						reject({msg: 'Log out unsuccessful'})
					})
				}else{
					reject({msg: 'Log out unsuccessful'})
				}
				
			})
		})
	}
	const refreshToken = ()=>{
		return new Promise((resolve, reject)=>{
			// send a request with refresh token for re-authinticated
			// const rt = ls.get('rt')
			const rt = localStorage.getItem('refresh')
			// check if refresh token is saved in local storage
			if(rt){
				// make a request to verify the refresh token
				axios({
					method: "POST",
					url: "https://magpie-webapp.azurewebsites.net/api/v1/token/refresh/",
					withCredentials: true,
					data: {
						refresh: rt
					}
				}).then(response=>{
					// console.log('Token refresh', response.data)
					localStorage.setItem('access', response.data.access)
					setIsLoggedIn(true)
					setUserLoading(false)
					resolve(response.data)
				}).catch(error=>{
					// console.log('Token expired')
					// console.log(error)
					localStorage.clear()
					setUserLoading(false)
					reject('Token expired')
				})
			}else{
				// console.log('No saved token')
				setUserLoading(false)
				reject('No saved token')
			}
		})
	}
	const getUserData = ()=>{
		return new Promise((resolve, reject)=>{
			if(isLoggedIn){
				const access = localStorage.getItem('access')
				const config = {
					method: 'GET',
					url: 'https://magpie-webapp.azurewebsites.net/api/v1/user/profile',
					withCredentials: true,
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${access}`
					}
				} 
				axios(config).then(response => {
					// console.log(response.data)
					// setUser(response.data)
					resolve(response.data)
				}).catch(error => {
					if (error.response.status === 401) {
						// console.log('Not authinticate')
						refreshToken().then(token => {
							// console.log('refresh from user profile', token)
							axios(config).then(response => {
								console.log(response.data)
								// setUser(response.data)
								resolve(response.data)
							}).catch(error=>{
								reject("Can't get user data")
							})
						}).catch(msg => {
							// console.log('error form user profile', msg)
							reject("Can't get user data")
						})
					}
				})
			}else{
				reject('You are not logged in')
			}
		})
	}
	useEffect(()=>{
		refreshToken().then((response)=>{
			// console.log('Login retrive ', response)
		}).catch(msg=>{
			// console.log(msg)
		})
		// console.log('Dam')
	},[])
	
	return (
		<UserContext.Provider value={{
			user, setUser, refreshToken, userLoading, logOutUser,
			isLoggedIn, setIsLoggedIn, phone_no, setPhone_no,
			mobileVerified, setMobileVerified, email, setEmail,
			userMobileNumber, setUserMobileNumber, getUserData
		}}>
			{{...props.children}}
		</UserContext.Provider>
	)
}

