import { Button, Container, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, FormControl, Box } from '@material-ui/core'
import React, { useState, useContext } from 'react'
import axios from 'axios'
import '../../styles/mobile_verifier.scss'
import firebase from '../../config/firebaseConfig'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import {Alert} from '@material-ui/lab'
import codeList from '../../assets/constant/code.json'
import ReactFlagsSelect from 'react-flags-select'
import countryLabel from '../../assets/constant/label.json'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme)=>({
	customSelect: {
		'&> button': {height: '55px'}
	}
}))
function MobileVerifier() {
	const classes = useStyles()
	const { setMobileVerified, setUserMobileNumber } = useContext(UserContext)
	const [mobileNumber, setMobileNumber] = useState("")
	const [countryCode, setCountryCode] = useState("+880")
	const [selectedCountryCode, setSelectedCountryCode] = useState('BD')
	const [dialogOpen, setDialogOpen] = useState(false)
	const [otp, setOpt] = useState("")
	const {phone_no, setPhone_no} = useContext(UserContext)
	const [alertMsg, setAlertMsg] = useState()
	const [disableBtn, setDisableBtn] = useState(false)
	const [helperText, setHelperText] = useState({
		cCode: "",
		cCodeError: false,
		mNumber: "",
		mNumberError: false
	})
	const history = useHistory()

	const handleSubmit = (e) => {
		e.preventDefault();
		// console.log(countryCode, mobileNumber)
		var mobilePattren = /^[0-9]{6,20}$/;
		if (countryCode !== "" && mobilePattren.test(mobileNumber)) {
			setDisableBtn(true)
			const fullNumber = countryCode+mobileNumber
			setPhone_no(fullNumber)
			axios({
				method: 'GET',
				url: 'https://magpie-webapp.azurewebsites.net/api/v1/is-phone-valid/'+fullNumber,
			}).then(_=>{
				// console.log(response)
				setAlertMsg('Mobile Number already Exist')
				setDisableBtn(false)
			}).catch(error=>{
				// console.log(error)
				if(error.response.status===404){
					// setAlertMsg("Valid mobile number")
					if (!window.appVerifier) {
						window.appVerifier = new firebase.auth.RecaptchaVerifier(
							"recaptcha-container",
							{
								size: "invisible"
							}
						)
					}
					const appVerifier = window.appVerifier;
					const number = countryCode + "" + mobileNumber
					firebase.auth().signInWithPhoneNumber(number, appVerifier).then((confirmationResult) => {
						// setDisableBtn(false)
						setDialogOpen(true)
						window.confirmationResult = confirmationResult;
					})
				}else{
					setAlertMsg('Error verify mobile number')
					setDisableBtn(false)
				}
			})
			/*
			if (!window.appVerifier) {
				window.appVerifier = new firebase.auth.RecaptchaVerifier(
					"recaptcha-container",
					{
						size: "invisible"
					}
				)
			}
			const appVerifier = window.appVerifier;
			const number = countryCode + "" + mobileNumber
			firebase.auth().signInWithPhoneNumber(number, appVerifier).then((confirmationResult) => {
				setDialogOpen(true)
				window.confirmationResult = confirmationResult;
			})
			*/
			// setDisableBtn(false)
		} else {
			setDisableBtn(false)
			setHelperText({ ...helperText, mNumber: "Please enter a valid number", mNumberError: true })
			// if (mobileNumber === "") {
			// }
		}
	}

	const handleConformation = () => {
		setDisableBtn(false)
		if (otp !== "") {
			window.confirmationResult.confirm(otp).then(result => {
				// console.log(result.user)
				setUserMobileNumber(result.user.phoneNumber)
				setDialogOpen(false)
				setMobileVerified(true)
				history.push("/signin")
			})
		} else {
			alert('Otp is blnak')
		}
	}
	const onSelect = (e)=>{
		setSelectedCountryCode(e)
		setCountryCode("+"+codeList[e])
	}

	return (
		<Container maxWidth="lg">
			<div id="recaptcha-container"></div>
			<div className="mobile-verifier-body">
				<Grid className="mobile-verifier-content" container direction="column" justify="center" alignItems="center">
					<div>
						<h3 className="title">Enter your phone number</h3>
						{alertMsg?(<Box mb={3}><Alert severity="error">{alertMsg}</Alert></Box>):""}
						<form onSubmit={handleSubmit}>
							<Grid container spacing={1}>
								<Grid item sm={4} xs={12}>
									<FormControl variant="outlined" style={{ width: '100%' }}>
										{/* <InputLabel id="demo-simple-select-outlined-label">Country Code</InputLabel> */}
										 <ReactFlagsSelect 
											searchable={true}
											selected={selectedCountryCode}
											className={classes.customSelect}
											customLabels={countryLabel}
											onSelect={onSelect}/> 
										{/* <Select
											labelId="demo-simple-select-outlined-label"
											id="demo-simple-select-outlined"
											value={countryCode}
											onChange={e => { setCountryCode(e.target.value)
											}}
											label="Age">
											{countryCodeList.map(one => (
												<MenuItem key={one.code} value={one.dial_code}>
													{one.flag} {one.code} ({one.dial_code})
												</MenuItem>
											))}
										</Select> */}
										 
									</FormControl>
								</Grid>
								<Grid item sm={8} xs={12}>
									<TextField fullWidth variant="outlined" placeholder="1464300880" label="Mobile number" value={mobileNumber} onChange={e => { setMobileNumber(e.target.value) }} helperText={helperText.mNumber} error={helperText.mNumberError} />
								</Grid>
								<Grid container justify="flex-end" style={{ marginTop: '20px' }}>
									<Button variant="contained" disabled={disableBtn} className="primary-color" style={{ marginRight: '10px' }} onClick={e => { history.push('/') }}>Cancel</Button>
									<Button variant="contained" type="submit" disabled={disableBtn} className="secondary-color">Validate</Button>
								</Grid>
							</Grid>
						</form>
					</div>
				</Grid>
			</div>
			<Dialog open={dialogOpen} onClose={() => { setDialogOpen(false) }} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">OTP verification</DialogTitle>
				<DialogContent>
					<DialogContentText>Please verify your otp to continue with Magpie Analytics</DialogContentText>
					<TextField autoFocus margin="dense" id="top" label="OTP code" type="text" fullWidth onChange={e => { setOpt(e.target.value) }} />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => { setDialogOpen(false) }} color="secondary">Cancel</Button>
					<Button color="primary" onClick={handleConformation}>Verify</Button>
				</DialogActions>
			</Dialog>
		</Container>
	)
}

export default MobileVerifier
// 01303819125


// https://www.sprint.com/en/support/solutions/international/international-mobile-dial-codes.html

// https://www.webnots.com/copy-paste-country-flag-emoji-symbols/