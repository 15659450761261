import React,{createContext,useState} from 'react'

export const StyleContext = createContext()

export function StyleProvider(props) {
	const [sideBarOpen,setSideBarOpen] = useState(true)
	const [message, setMessage] = useState({})
	return (
		<StyleContext.Provider value={{
			sideBarOpen, setSideBarOpen,
			message, setMessage
		}}>
			{{...props.children}}
		</StyleContext.Provider>
	)
}
